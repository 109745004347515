<template>
  <b-card title="Premi del calendario">
    <b-card-text>Qui puoi visualizzare e configurare tutti i premi del calendario.</b-card-text>
    <div
        v-if="loading"
    >
      <b-spinner
        variant="primary"
        class="spinner-def"
      />
    </div>
    <div v-if="!loading" class="prize-container justify-content-start">
      <div
        v-for="(el, i) in 24"
        :key="i"
        class="prize-item"
      >
        <span class="day-badge">Giorno {{ el }}</span>
        <img
          @click="selectDay(el)"
          :src="getImageOfTheDay(el)"
          class="prize-image"
        >
        <div class="title-desc">
            <input @change="setPrizeInDb(el,prizes[el-1])" v-model="prizes[el-1].title" class="title" @click.stop.prevent placeholder="Titolo" type="text">
            <textarea @change="setPrizeInDb(el,prizes[el-1])" v-model="prizes[el-1].description" class="desc" @click.stop.prevent> Descrizione del premio</textarea>
            <b-form-checkbox
              v-model="prizes[el-1].limited"
              :checked="prizes[el-1].limited"
              name="check-button"
              switch
              inline
              class="mg-10"
              @change="setPrizeInDb(el,prizes[el-1])"
            >
              <span v-if="prizes[el-1].limited">
                Limitazione abilitata
              </span>
              <span v-else>
                Limitazione disabilitata
              </span>
            </b-form-checkbox>
            <input v-if="prizes[el-1].limited" @change="setPrizeInDb(el,prizes[el-1])" v-model="prizes[el-1].limits" class="limit" @click.stop.prevent placeholder="limite utilizzo" type="number">
        </div>
        <input
          :id="'day_' + el"
          class="not-shown"
          accept="image/*"
          type="file"
          @change="LoadPrize(el, $event)"
        >
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import imageToBase64 from 'image-to-base64/browser'

export default {
  components: {
    BCard,
    BCardText,
    BSpinner,
    BFormCheckbox,
  },
  data() {
    return {
      prizes: [],
      loading: true,
    }
  },
  mounted() {
    this.$http.get('/avvento/prize').then(response => {
      this.loading = false
      const x = response.data
      response.data.forEach((item, index) => {
        if (item.limited === 1) {
          x[index].limited = true
        } else {
          x[index].limited = false
        }
      })
      this.prizes = x
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Premi natalizi caricati correttamente.',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    }).catch(() => {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Errore nel recupero dei premi natalizi.',
          icon: 'AlertCircleIcon',
          variant: 'warning',
        },
      })
    })
  },
  methods: {
    setPrizeInDb(day, el) {
      const cpyel = el
      if (cpyel.title === null) {
        cpyel.title = ''
      }
      if (cpyel.description === null) {
        cpyel.description = ''
      }
      if (cpyel.limits === null || cpyel.limits === undefined || cpyel.limits === '') {
        cpyel.limits = 0
      }
      this.$http.put(`/avvento/prize/${day}`, cpyel).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Salvato correttamente.',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Impossibile salvare.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
    },
    getImageOfTheDay(el) {
      if (this.prizes[el - 1].image.trim() !== '') {
        return this.prizes[el - 1].image
      }
      return '/imgs/no-image.png'
    },
    LoadPrize(day, e) {
      const file = e.target.files[0]
      const url = URL.createObjectURL(file)
      imageToBase64(url)
        .then(
          response => {
            this.prizes[day - 1].image = `data:image/png;base64,${response}`
            this.setPrizeInDb(day, this.prizes[day - 1])
          },
        )
        .catch(
          () => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Impossibile caricare il premio.',
                icon: 'AlertIcon',
                variant: 'success',
              },
            })
          },
        )
    },
    selectDay(el) {
      //    console.log(this.$refs[`day_${el}`])
      document.getElementById(`day_${el}`).click()
    },
  },
}
</script>

<style scoped>
.prize-container{
  display: flex;
  flex-flow: row wrap;
  position: relative;
  flex-wrap: wrap;
  -ms-flex-align: center;
}
.prize-item{
  background: rgb(243, 243, 243);
  margin: 25px;
  position: relative;
  flex: 0 1 calc(33% - 50px); /* <-- adjusting for margin */
  min-height: 100px;
  flex-wrap: wrap;
  -ms-flex-align: center;
  text-align: center;
  color: rgb(121, 37, 37);
  padding: 15px;
  padding-top: 45px;
  font-size: 20px;
  border-radius: 15px;
  cursor: pointer;
   box-shadow: 0 10px 20px rgba(168, 168, 168, 0.19), 0 6px 6px rgba(146, 146, 146, 0.23);
}
.not-shown{
    display: none;
}
.title-desc{
    font-size: 15px;
}
.title-desc input {
    border: none;
    border-radius: 15px;
    padding: 6px 15px;
    width: 100%;
}
.title {
    margin-top: 25px;
    margin-bottom: 5px;
}
.title-desc textarea {
    width: 100%;
    border: none;
    border-radius: 15px;
    padding: 6px 15px;
}
span.day-badge {
    position: absolute;
    background: #d64141;
    padding: 5px 14px;
    border-radius: 25px;
    color: white;
    top: -15px;
    left: 50%;
    transform: translate(-50%);
}
.prize-image{
    max-width: calc(100% - 15px);
    border-radius: 15px;
    max-height: 290px;
}
.mg-10{
    margin: 10px;
}
</style>
